.custom-table {
  font-size: 12px;
  background-color: #f0f0f0;
  border-radius: none !important;

  &.is-detail {

    td,
    th {
      width: auto !important;
    }
  }

  td,
  th {
    border: 1px solid #E9E9E9 !important;
    width: calc(100% / 6) !important;

    &:before {
      display: none !important;
    }

    &:hover {
      opacity: .8 !important;
    }
  }

  tr {
    &:nth-child(even) {
      background-color: #F4F4F4 !important;

      &:hover {
        background-color: #F4F4F4 !important;
      }
    }
  }

  thead th {
    font-size: 12px !important;
    font-weight: bold !important;
    background-color: #F4F4F4 !important;
    text-align: center !important;
    padding: 12px 8px !important;
    border-top: none !important;

    &:hover {
      background-color: #F4F4F4 !important;
    }
  }

  td {
    font-size: 14px !important;
    padding: 12px 8px !important;
  }


}

.dark .custom-table {
  background-color: #16284B;

  .ant-table-row>.ant-table-cell-row-hover {
    background-color: #15223B !important;
  }

  th {
    background-color: #16284B !important;
  }

  tr {
    background-color: #15223B !important;

    &:hover {
      background-color: #15223B !important;
    }

    &:nth-child(even) {
      background-color: #16284B !important;

      &:hover {
        background-color: #16284B !important;
      }
    }
  }

  th,
  td {
    color: #fff !important;
    border-color: #1D2E4F !important;
  }
}