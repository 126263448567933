/* styles/tailwind.css */
.wrapper {
  width: 100%;
  padding: 0 10px;
  max-width: 1440px;
  margin: 0 auto;
}

.is-full .wrapper {
  max-width: unset;
  height: 30%
}

.trans {
  transition: all .3s ease;
}

.is-full .custom-height {
  height: calc(100vh - 200px);
}

.table-product {
  max-height: 500px;
}

.is-full .table-product {
  max-height: calc(100vh - 335px)
    /* max- */
}
.custom-iframe {
  position: relative;
  padding-top: 62.5%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}