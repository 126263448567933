@font-face {
  font-family: 'SFPro';
  src: url('../fonts/SF-Pro-Display-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('../fonts/SF-Pro-Display-Semibold.woff') format('woff');
  font-weight: semibold;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('../fonts/SF-Pro-Display-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('../fonts/SF-Pro-Display-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

body {
  font-family: 'SFPro', sans-serif;
}